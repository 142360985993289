<template>
    <div class="body">
        <div class="search-wrapper">
            <form action="/">
                <van-search
                        v-model="keyWord"
                        placeholder="请输入搜索关键词"
                        @search="onSearch"
                        @cancel="onCancel"
                />
            </form>
            <div class="search-groups-wrapper">
                <div class="search-groups-tag">类别</div>
                <div class="search-groups-info-wrapper">
<!--                    <div class="search-groups-item" :class="levelIndex1 === '' ? 'search-groups-item-active' : ''" @click="switchItems(1,'','')">全部</div>-->
                    <div v-for="(items,index) in groups" :key="index">
                        <div class="search-groups-item" :class="levelIndex1 === index ? 'search-groups-item-active' : ''" @click="switchItems(1,items.id,index)">{{items.name}}</div>
                    </div>
                </div>
            </div>
            <transition name="side-up">
                <div class="search-groups-wrapper" v-if="levelIndex1 !== ''">
                    <div class="search-groups-tag">分类</div>
                    <div class="search-groups-info-wrapper">
                        <div class="search-groups-item" :class="levelIndex2 === '' ? 'search-groups-item-active' : ''" @click="switchItems(2,groups[levelIndex1].id,'')">全部</div>
                        <div v-for="(items,index) in groups[levelIndex1].list" :key="index">
                            <div class="search-groups-item" :class="levelIndex2 === index ? 'search-groups-item-active' : ''" @click="switchItems(2,items.id,index)">{{items.name}}</div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="side-up">
                <div class="search-groups-wrapper" v-if="levelIndex2 !== ''">
                    <div class="search-groups-tag">分类</div>
                    <div class="search-groups-info-wrapper">
                        <div class="search-groups-item" :class="levelIndex3 === '' ? 'search-groups-item-active' : ''" @click="switchItems(3,groups[levelIndex1].list[levelIndex2].id,'')">全部</div>
                        <div v-for="(items,index) in groups[levelIndex1].list[levelIndex2].list" :key="index">
                            <div class="search-groups-item" :class="levelIndex3 === index ? 'search-groups-item-active' : ''" @click="switchItems(3,items.id,index)">{{items.name}}</div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="product-list-wrapper" v-if="listLoadingList.length > 0">
            <van-list
                    v-model="listLoading"
                    :finished="listLoadingFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                    <product-mid-size-item v-for="(item, index) in listLoadingList" :key="index"
                                           :isSellOut="item.is_sellout"
                                           :onlineStart="item.online_start"
                                           :onlineEnd="item.online_end"
                                           :orgPrice="item.price"
                                           :price="item.mallprice"
                                           :name="item.name"
                                           :distributorPrice="item.c_level1_money"
                                           :img="item.images[0]"
                                           :id="item.id"
                                           :isDistributor="isDistributionMember"
                                           :cellId="item.product_cell_id"
                    ></product-mid-size-item>
            </van-list>
        </div>
        <nav-bar></nav-bar>
    </div>
</template>

<script>
  import NavBar from '../../components/common/NavBar'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import ProductMidSizeItem from '../../components/product/ProductMidSizeItem'
  import { getStationId } from '../../utils/tools'
  import { getCookie } from '../../utils/cookie'
  export default {
    name: 'Search',
    components: { ProductMidSizeItem, NavBar },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        keyWord: '',
        groups: [],
        isDistributionMember: false,
        currentGroup: '',
        levelIndex1: '',
        levelIndex2: '',
        levelIndex3: '',
        currentStation: 1
      }
    },
    methods: {
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/search', {
          stationId: this.currentStation
        }).then((response) => {
          const data = response.data
          console.log(data)
          that.groups = data.list
          this.defaultSelect()
          that.getData()
        })
      },
      /**
       * 点击tab切换
       * @param level 点击的tab层级，目前为3级
       * @param group 分组id
       * @param index 当前分类在分组数组中的index 主要用来定位数组
       */
      switchItems: function (level, group, index) {
        this.currentGroup = group
        if (level === 1) {
          this.levelIndex1 = index
          this.levelIndex2 = ''
          this.levelIndex3 = ''
        } else if (level === 2) {
          this.levelIndex2 = index
          this.levelIndex3 = ''
        } else if (level === 3) {
          this.levelIndex3 = index
        }
        console.log('当前要显示的分组' + group, index, level)
        this.getData()
      },
      onSearch: function () {
        this.getData()
      },
      onCancel: function () {
        this.getData()
      },
      getData: function () {
        this.listLoadingInit(this.apiUrl + 'mall/Product/getGroupProductList', 'list', 1, {
          keyword: this.keyWord,
          group: this.currentGroup,
          stationId: this.currentStation
        })
      },
      // 2022.01.20 默认选择，如果要改回去，init里面这个函数屏蔽，然后上面有一句注释打开即可
      defaultSelect: function () {
        this.currentGroup = 1
        this.levelIndex1 = 0
        this.levelIndex2 = ''
        this.levelIndex3 = ''
      }
    },
    created () {
      this.currentStation = getStationId()
      this.isDistributionMember = getCookie('isDistributionMember')
      this.init()
      console.log('search page created')
    },
    mounted () {
      console.log('search page mounted')
    },
    activated () {
      console.log('激活')
    },
    destroyed () {
      console.log('销毁')
    }
  }
</script>

<style scoped>
    .body {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#eee;
    }
    .search-wrapper {
        background-color: #FFF;
        padding: 0 0 2vw 0;
        border-radius: 0 0 5vw 5vw;
    }
    .search-groups-wrapper{
        display: flex;
        background-color: #ffffff;
    }
    .search-groups-tag{
        width: 18vw;
        height: 7vw;
        line-height: 7vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/icon2020010801.png") center no-repeat;;
        background-size: 80% 100%;
        font-size: 3vw;
        font-weight: 600;
        text-align: center;
        color: #ff4040;
        margin-top: 0.5vw;
    }
    .search-groups-info-wrapper{
        width: 82vw;
        display: flex;
        flex-wrap: wrap;
    }
    .search-groups-item{
        width: 18vw;
        padding: 1vw 0.5vw;
        margin-bottom: 2vw;
        font-size: 3.2vw;
        text-align: center;
    }
    .search-groups-item-active{
        font-weight: 700;
        color: #ff4040;
        border: 1px solid #ff4040;
        border-radius: 1vw;
    }
    .product-list-wrapper {}
</style>
